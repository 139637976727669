import React, { useState } from 'react'
import { Button, Input } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'
import { isEmpty } from 'lodash'

import { FilterColumnButton } from '../FilterColumnButton'
import { FilterPill } from '../FilterPill'
import { FilterTypePopup } from './FilterTypePopup'

export const FilterTypeText = ({ schema, value, loading, updateSearch, columnAccessor }) => {
  const [open, setOpen] = useState(false)

  return (
    <FilterTypePopup
      open={open}
      setOpen={setOpen}
      trigger={
        columnAccessor ? (
          <FilterColumnButton
            columnAccessor={columnAccessor}
            value={value}
            hasValue={!isEmpty(value)}
            displayLabel={schema.label}
          />
        ) : (
          <FilterPill
            clickable
            isClearable={!isEmpty(value)}
            handleRemoveFilter={(event) => {
              event.stopPropagation()

              updateSearch(schema.accessor, '')
            }}
            loading={loading}
            label={schema.label}
            value={value}
          />
        )
      }
    >
      <div className="flex-align-center small-gap">
        <Input
          autoFocus
          type="text"
          data-testid="filter-type-text-input"
          className="full-width"
          placeholder={`Search ${schema.label}`}
          value={value || ''}
          onChange={(event) => {
            updateSearch(schema.accessor, event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setOpen(false)
            }
          }}
        />
        <Button
          basic
          icon
          className="svg-button borderless"
          compact
          onClick={() => {
            updateSearch(schema.accessor, '')
            setOpen(false)
          }}
        >
          <IconX />
        </Button>
      </div>
    </FilterTypePopup>
  )
}

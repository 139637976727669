import React from 'react'
import { components } from 'react-select'
import { Button, Checkbox, Popup } from 'semantic-ui-react'
import pluralize from 'pluralize'
import { isEmpty } from 'lodash'

function getBgColor(state) {
  if (state.isFocused) {
    return 'var(--blurple-100)'
  }

  if (state.isSelected) {
    return 'var(--blurple-200)'
  }

  return 'white'
}

export const selectStyles = (fixedWidth, error) => ({
  container: (provided) => ({
    ...provided,
    minWidth: '190px',
    maxWidth: fixedWidth ? '300px' : '100%',
    borderRadius: 'var(--border-radius-button)',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 'var(--border-radius-button)',
    borderColor: error ? 'var(--color-fill-critical)' : 'var(--dark-knight-300)',
    borderWidth: error ? '2px' : '1px',
    background: state.isDisabled ? 'var(--dark-knight-100)' : 'white',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: 'var(--dark-knight-500)',
    fontSize: '0.9rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--dark-knight-500)',
  }),
  menu: (provided) => ({
    ...provided,
    width: fixedWidth ? '300px' : '100%',
    zIndex: 40,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: state.isSelected ? 600 : 500,
    lineHeight: 1.2,
    padding: '0.5rem 0.75rem',
    background: getBgColor(state),
    color: 'var(--dark-knight-1000)',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? 'var(--dark-knight-600)' : 'var(--dark-knight-1000)',
  }),
})

export const multiSelectStyles = (fixedWidth, condenseOptions, showFullOptions) => ({
  ...selectStyles(fixedWidth),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: showFullOptions ? 'wrap' : 'noWrap',
    padding: '2px 6px',
  }),
  option: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1rem',
    fontWeight: state.isSelected ? 600 : 500,
    lineHeight: 1.2,
    padding: '.6rem .75rem',
    background: getBgColor(state),
    opacity: state.isDisabled ? 0.5 : 1,
    color: 'var(--dark-knight-1000)',
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'var(--dark-knight-100)',
    borderRadius: 'var(--border-radius-button)',
    fontSize: '1rem',
    fontWeight: 600,
    maxWidth: condenseOptions ? '100px' : 'unset',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    borderRadius: 'var(--border-radius-button)',
    fontSize: '100%',
    padding: '3px 6px',
  }),
  multiValueRemove: (provided) => {
    return condenseOptions ? { display: 'none' } : provided
  },
})

export const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: '#edf4ff',
    primary50: '#a1bcff',
    primary75: '#7c95fd',
    primary: '#4b60f6',
  },
})

export const MenuList = (props) => {
  if (!props.selectProps.isSelectAll) {
    return <components.MenuList {...props}>{props.children}</components.MenuList>
  }
  let optionsLength = 0
  if (props.children.length) {
    props.children.forEach((item) => {
      optionsLength += item.options ? item.options.length : 1
    })
  } else if (props.selectProps.inputValue === '') {
    optionsLength = props.selectProps.optionsLength ?? 0
  }
  const itemCount = pluralize(props.selectProps.label ?? 'item', optionsLength, true)

  return (
    <components.MenuList {...props}>
      <div style={{ opacity: 0.7, padding: '0.2rem .75rem 0', textAlign: 'left' }}>{itemCount}</div>
      <hr style={{ opacity: 0.3 }} />
      {props.children}
    </components.MenuList>
  )
}

export const ExclusiveMenuList = (props) => {
  const { labels, value, visibleSelectIndex, onVisibleSelectChange } = props.selectProps
  return (
    <>
      <div className="flex">
        {labels.map((label, index) => {
          const isButtonDisabled = !isEmpty(value) && visibleSelectIndex !== index
          const isPopupDisabled = !isButtonDisabled
          return (
            <Popup
              basic
              key={label}
              content="You must clear values to access this tab"
              disabled={isPopupDisabled}
              trigger={
                <span>
                  <Button
                    className={`rtc__border_bottom_button${
                      visibleSelectIndex === index ? '--active' : ''
                    }`}
                    type="button"
                    onClick={() => onVisibleSelectChange(index)}
                    disabled={isButtonDisabled}
                  >
                    {label}
                  </Button>
                </span>
              }
            />
          )
        })}
        <span onClick={() => props.clearValue()} className="rtc__inline_button">
          Clear
        </span>
      </div>
      <hr className="rtc__hr_no_margin" />
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </>
  )
}

export const Option = (props) => {
  const { innerProps, isFocused, ...otherProps } = props
  const newProps = { innerProps: { ...innerProps }, ...otherProps }

  return (
    <components.Option
      {...newProps}
      className={`${newProps.isDisabled ? 'option-component-disabled' : 'option-component'}`}
    >
      <Checkbox checked={props.isSelected} style={{ marginRight: '1rem', pointerEvents: 'none' }} />
      <div>{props.label}</div>
    </components.Option>
  )
}

export const MultiValue = (props) => {
  if (props.index > 0) return null
  const { length } = props.getValue()

  if (props.selectProps.allowSelectAll) {
    const { options = [] } = props.options?.[0] ?? {}
    if (options.length === length) {
      return <div className="option-label">All</div>
    }
  }

  return length > 1 ? (
    <div className="option-label">{`${length} selected`}</div>
  ) : (
    <components.MultiValue {...props} />
  )
}

export const Input = (props) => {
  if (props.isHidden) {
    return <components.Input {...props} />
  }
  return <components.Input {...props} type="number" className="creatable-number-field" />
}
